* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #000000;
}

body {
  background: #000000;
  font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
  color: #fff;
  margin: 0;
  /* center the text */
  display: flex;
  justify-content: center;
  transform: translateY(110%);
  z-index: 1;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: 0.04em;
  white-space: nowrap;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
}

.footer footer-text {
  display: inline-block;
  margin: 0 0.5rem;
}
